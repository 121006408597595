import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { uploadBytesResumable, ref, FirebaseStorage, getDownloadURL } from 'firebase/storage';

export const FIREBASE_STORAGE = new InjectionToken<FirebaseStorage>('FIREBASE_STORAGE')

export type UploadProgress = { progress: number, url: string | null }

@Injectable({
	providedIn: 'root',
})
export class FileService {

	constructor(
		@Inject(FIREBASE_STORAGE) private storage: FirebaseStorage
	) {
	}

	uploadFile(file: File, path: string): Observable<UploadProgress> {
		const storageRef = ref(this.storage, path);
		const task = uploadBytesResumable(storageRef, file);
		return new Observable<{ progress: number; url: string | null }>((observer) => {
			task.on(
				'state_changed',
				(snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					observer.next({ progress: progress, url: null });
				},
				(error) => {
					observer.error(error);
				},
				() => {
					getDownloadURL(task.snapshot.ref).then((downloadURL) => {
						observer.next({ progress: 100, url: downloadURL });
						observer.complete();
					});
				}
			);
		});
	}
}

/**
 * Wraps the services uploadFile observable and returns a promise that resolves when the observable completes.
 *
 * This is a utility for procedures that don't care about the upload progress, as it's silently ignored here; only
 * the final image URL is returned.
 *
 * @example
 * await awaitFileUpload(fileService.uploadFile(someFile, '/path/to/file'))
 */
export async function awaitFileUpload(observable: Observable<UploadProgress>): Promise<string> {
	return new Promise((resolve, reject) => {
		let url = ''
		observable.subscribe({
			next(ev) {
				if (ev.url) url = ev.url
			},
			error: reject,
			complete() {
				if (url) resolve(url)
				else reject(new Error('did not receive url from download'))
			}
		})
	})
}