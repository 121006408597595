import { InputValidationMessageProvider } from "@freshfox/ng-core";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root',
})
export default class TranslatingValidationMessageProvider implements InputValidationMessageProvider {
	constructor (private t: TranslateService) {
	}

	getValidationMessage(validatorName: string, validatorValue?: any): string {
		return this.t.instant(`common.errors.${validatorName}`, validatorValue)
	}
}