import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FFAuthModule } from '@freshfox/ng-auth';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { FFFirestoreModule } from '@freshfox/ng-firestore';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { FFFormControlValidationMessageModule, FFInputModule, InputValidationMessageProvider } from '@freshfox/ng-core';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FIREBASE_STORAGE } from "./common/FileService"
import { StoreModule } from "@ngrx/store";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import TranslatingValidationMessageProvider from "@/common/TranslatingValidationMessageProvider";
import { EffectsModule } from "@ngrx/effects";
export const FIREBASE_APP = new InjectionToken('FIREBASE_APP');

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FFAuthModule.forRootAsync({
			deps: [FIREBASE_APP],
			useFactory: (app: FirebaseApp) => {
				const auth = getAuth(app);
				if (environment.emulator) {
					connectAuthEmulator(auth, `http://localhost:9099`, { disableWarnings: true });
				}
				return auth;
			}
		}),
		FFFirestoreModule.forRootAsync({
			deps: [FIREBASE_APP],
			useFactory: (app: FirebaseApp) => {
				const firestore = getFirestore(app);
				if (environment.emulator) {
					connectFirestoreEmulator(firestore, `localhost`, 8080);
				}
				return firestore;
			},
		}),
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				deps: [HttpClient],
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => {
					return new TranslateHttpLoader(http, './assets/i18n/', '.json');
				}
			},
			compiler: {
				provide: TranslateCompiler,
				useFactory: () => {
					return new TranslateMessageFormatCompiler();
				}
			}
		}),
		FFFormControlValidationMessageModule.forRoot({
			validationMessageProvider: {
				provide: InputValidationMessageProvider,
				useClass: TranslatingValidationMessageProvider,
			}
		}),
		FFInputModule,
	],
	providers: [
		{
			provide: FIREBASE_APP,
			useFactory: () => {
				return initializeApp(environment.firebase);
			}
		},
		{
			deps: [FIREBASE_APP],
			provide: FIREBASE_STORAGE,
			useFactory: (app: FirebaseApp) => {
				let storage = getStorage(app)
				if (environment.emulator) {
					connectStorageEmulator(storage, 'localhost', 9199)
				}
				return storage
			}
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {duration: 5000}
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private translate: TranslateService) {
		this.translate.use('en');
	}
}
