export const environment = {
  production: false,
  emulator: false,
  firebase: {
    apiKey: "AIzaSyC25qG5KqQObNHMawfbHTTaJwQk58d36GA",
    authDomain: "cyberobics-dev.firebaseapp.com",
    databaseURL: "https://cyberobics-dev.firebaseio.com",
    projectId: "cyberobics-dev",
    storageBucket: "cyberobics-dev.appspot.com",
    messagingSenderId: "441484139648",
    appId: "1:441484139648:web:0b3545a58e5e3ec81f9a65",
  },
  adminApi: {
    url: 'https://cyberobics-dev-admin-api.web.app',
  }
};
