import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot([
      { path: '', loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule) },
      { path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
    ])
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
